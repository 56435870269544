export default function logoMarker(url, setPlaceMarker) {
    const imag = new Image();
    imag.onload = e => {
        let width = 64, height = width;
        const elem = document.createElement('canvas');
        if ((imag.width / imag.height) < (width / height)) width = Math.round(height / imag.height * imag.width);
        else height = Math.round(width / imag.width * imag.height);
        elem.width = width;
        elem.height = height * 1.2;
        const ctx = elem.getContext('2d');
        ctx.beginPath();
        ctx.arc(width / 2, height / 2, width * .4, 0, 2 * Math.PI);
        ctx.lineWidth = width * .15;
        ctx.strokeStyle = "#425e91";
        ctx.stroke();
        ctx.closePath();
        ctx.beginPath();
        ctx.moveTo(width * .15, height * .82);
        ctx.lineTo(width * .5, height * 1.2);
        ctx.lineTo(width * .85, height * .82);
        ctx.fillStyle = "#425e91";
        ctx.fill();
        ctx.closePath();
        ctx.beginPath();
        ctx.arc(width / 2, height / 2, width * .4, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.clip();
        ctx.drawImage(imag, width * .1, height * .1, width * .8, height * .8);
        const dataUrl = ctx.canvas.toDataURL();
        setPlaceMarker(dataUrl);
    };
    imag.src = url;
}