import { storage, rtdb } from "./firebase";
import { ref as storageRef, uploadString, getDownloadURL } from "firebase/storage";
import { sha256 } from 'crypto-hash';
import { ref as rtdbRef, set } from "firebase/database";
import logoMarker from "./logoMarker.js";

export default function picStorageUpload(transparency = false, files, addHash, PicsList, UploadingPics = [], setUploadingPics = () => { }, setPlaceMarker=()=>{}) {
    for (let file of files) {
        if (file.type.split('/')[0] === 'image') {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = event => {
                const imag = new Image();
                imag.src = event.target.result;
                imag.onload = e => {
                    let width = transparency ? 64 : 640, height = transparency ? 64 : 640;
                    const elem = document.createElement('canvas');
                    elem.width = width;
                    elem.height = height;
                    if ((imag.width / imag.height) < (width / height)) elem.width = Math.round(height / imag.height * imag.width);
                    else elem.height = Math.round(width / imag.width * imag.height);
                    const ctx = elem.getContext('2d');
                    ctx.drawImage(imag, 0, 0, elem.width, elem.height);
                    const dataUrl = transparency ? ctx.canvas.toDataURL() : ctx.canvas.toDataURL('image/jpeg', .8);
                    logoMarker(dataUrl,setPlaceMarker);
                    sha256(dataUrl).then(hash => {
                        addHash(hash);
                        if (!PicsList.includes(hash)) {
                            setUploadingPics([...UploadingPics, hash]);
                            uploadString(
                                storageRef(storage, 'pics/' + hash + (transparency ? '.png' : '.jpg')),
                                dataUrl,
                                'data_url'
                            ).then((snapshot) => {
                                getDownloadURL(snapshot.ref)
                                    .then(url => set(rtdbRef(rtdb, "public/Pictures/" + hash),
                                        { ts: new Date().getTime(), token: url.split('token=')[1] }
                                    )
                                    );
                            });
                        }

                    });
                };
            };
        } else addHash(null);
    }
}